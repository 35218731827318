import { FormControl, styled } from "@mui/material"

export const SelectInputBox = styled(FormControl)(({ theme, color }) => ({
  width: "100%",

  "& .MuiInputLabel-formControl": {
    fontSize: "0.85rem",
    color: `${color ?? theme.palette.text}`,
  },

  "& .MuiInputLabel-formControl.Mui-focused": {
    padding: "0 8px",
  },

  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.text,
  },

  "&.MuiFormLabel-root.Mui-error": {
    color: "#F44336",
  },
  "& .feedback": {
    color: "#F44336",
    paddingBottom: theme.spacing(1.5),

    "& span": {
      fontSize: "0.85rem",
    },
  },
  "& .select__input": {
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(121, 121, 121, 0.87)",
      borderWidth: 1,
    },

    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "16px 18px",
      color: theme.palette.text,
    },

    "& .MuiSelect-iconOutlined": {
      color: theme.palette.primary.main,
    },

    "& .MuiInputBase-input": {
      backgroundColor: "#eaeff1",
    },
  },
}))
