import { AuthContainerBox } from "./AuthContainer.style";
import { LogoTextLightBg } from "../../../../components";
import * as React from "react";

interface IProps {
  title: string;
  details: string;
  children: JSX.Element;
}

export const AuthContainer: React.FC<IProps> = ({
  title,
  details,
  children,
}) => {
  return (
    <AuthContainerBox>
      <div className="authContainer">
        <div className="logoContainer">
          <LogoTextLightBg />
        </div>

        <section className="headerSection">
          <h4 className="title">{title}</h4>
          <p className="details mb-0">{details}</p>
        </section>

        <section>{children}</section>
      </div>
    </AuthContainerBox>
  );
};
