import { styled } from "@mui/material";

export const AppointmentBox = styled("section")(({ theme }) => ({
  borderRadius: "8px",
  background: "#FFFFFF",
  padding: "2.5rem",
  height: "100%",

  "& .title": {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: "1rem",
  },

  "& .details": {
    fontSize: "1rem",
    fontWeight: 400,
    color: "#424242",
    marginBottom: "2.5rem",
  },

  "& .editor-interface": {
    background: "#0C0C0C",
    padding: "1.5rem",
    borderRadius: "8px",
    whiteSpace: "pre-wrap",
    overflow: "auto",
    lineHeight: 1.5,

    "& .css-selector": {
      color: "rgb(199, 146, 234)",
    },

    "& .css-property-name": {
      color: "rgb(137, 221, 255)",
    },

    "& .css-property-value, .css-bracket, .html-text": {
      color: "#ffffff",
    },

    "& .html-comment": {
      fontStyle: "italic",
      color: "rgb(133, 138, 168)",
    },

    "& .html-tag": {
      color: "rgb(255, 88, 116)",
    },

    "& .html-attribute": {
      color: "rgb(137, 221, 255)",
    },

    "& .html-attribute-value": {
      color: "rgb(80, 250, 123)",
    },

    // "& .html-text": {
    //   marginLeft: "0.5rem",
    // },
  },
}));
