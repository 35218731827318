import { styled } from "@mui/material";

export const DashboardBox = styled("section")(({ theme }) => ({
  "& .metricCardContainer": {
    display: "flex",
    alignItems: "center",
    gap: "0 1.25rem",
    marginBottom: "2rem",

    "& .metricCard": {
      padding: "1rem",
      borderRadius: "8px",
      width: "300px",
      fontWeight: 600,
      position: "relative",
      transition: "0.5s all ease-in-out",

      "&: hover": {
        transform: "scale(1.025)",
      },

      "& .title, .number": {
        color: "#0C0C0C",
        fontSize: "0.875rem",
      },

      "& .number": {
        fontSize: "2.5rem",
        margin: "1.5rem 0 0.75rem",
      },

      "& .metricNote": {
        fontSize: "0.75rem",
        display: "flex",
        alignItems: "center",
      },

      "& .success": {
        color: "#14994A",

        "& svg": {
          "& path": {
            fill: "#14994A",
          },
        },
      },

      "& .failed": {
        color: "#F71C1C",

        "& svg": {
          "& path": {
            fill: "#F71C1C",
          },
        },
      },

      "& .icon": {
        position: "absolute",
        bottom: "0.5rem",
        right: "0.375rem",
      },

      "&:nth-child(1)": {
        background: "#E7F3FE",
      },

      "&:nth-child(2)": {
        background: "#CEF7F7",
      },

      "&:nth-child(3)": {
        background: "#FCF1D2",
      },
    },
  },

  "& .btnContainer": {
    margin: "1rem 0 3rem",
    display: "flex",
    gap: "0 1rem",

    "& .btnOutline": {
      borderRadius: "8px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      background: "transparent",
    },
  },
}));
