import { styled } from "@mui/material";

export const BaseBox = styled("div")(({ theme }) => ({
  "& .root": {
    display: "block",
    background: "#FAFAFB",
  },

  "& .drawer": {
    zIndex: 1000,
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      width: theme.drawerWidth,
    },
  },

  "& .appContent": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  "& .mainContent": {
    flex: 1,
    padding: "2rem",
    background: "#FAFAFB",
    minHeight: `calc(100vh - 75px)`,

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.drawerWidth,
    },

    [theme.breakpoints.down("xs")]: {
      padding: "2rem 1rem",
    },
  },
}));
