import { styled } from "@mui/material";

export const EventCardBox = styled("section")(({ theme }) => ({
  border: "1px solid #E4E4E4",
  borderRadius: "8px",
  padding: "1rem 1rem 1.5rem",
  position: "relative",

  "& .newTag": {
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "8px 0px",
    background: "#D8E8FC",
    color: theme.palette.secondary.main,
    padding: "0.25rem 0.5rem",
  },

  "& .bannerHolder": {
    height: "130px",
    background: "#F5F5F5",
    borderRadius: "8px",
    marginBottom: "1rem",
  },

  "& .title": {
    fontSize: "1.125rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: "0.5rem",
  },

  "& .dateTimeContainer, .tagsContainer": {
    display: "flex",
    alignItems: "center",
    gap: "0 0.65rem",
  },

  "& .dateTimeContainer": {
    color: "#787878",
    fontSize: "0.875rem",
    fontWeight: 600,

    "& .date, .time": {
      display: "inline-flex",
      gap: "0 0.25rem",
      alignItems: "center",
    },
  },

  "& .tagsContainer": {
    margin: "1rem 0 1.5rem",

    "& .meetingTag, .timeTag, .costTag": {
      padding: "0.25rem 0.5rem",
      color: "#0C0C0C",
      fontSize: "0.875rem",
      fontWeight: 400,
    },

    "& .meetingTag": {
      background: "#FAE3A5",
      borderRadius: "100px",
    },

    "& .timeTag": {
      background: "#CEF7F7",
      borderRadius: "8px",
    },

    "& .costTag": {
      background: "#F5F5F5",
      borderRadius: "100px",
    },
  },

  "& .links": {
    display: "inline-flex",
    alignItems: "center",
    gap: "0 0.65rem",
    fontWeight: 600,
    fontSize: "0.875rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));
