import { ParticipantBox } from "./Participant.styles";
import { PageTitle, DataTable } from "../../../components";

const TableHeader = [
  {
    field: "apiName",
    headerName: "API Name",
    type: "string",
    minWidth: 150,
    maxWidth: 300,
    // renderCell: (params: GridRenderCellParams<any>) => (
    //   <Typography>{params.row.apiName}</Typography>
    // ),
  },
  {
    field: "description",
    headerName: "Description",
    type: "string",
    minWidth: 250,
    // renderCell: (params: GridRenderCellParams<any>) => (
    //   <Typography sx={{ pl: 1 }}>{params.row.description}</Typography>
    // ),
  },
];

export const Participants = () => {
  return (
    <ParticipantBox>
      <PageTitle title="Participants" btnText="Export" />

      <DataTable rows={[]} columns={TableHeader} />
    </ParticipantBox>
  );
};
