import { styled } from "@mui/material";

export const SignUpBox = styled("div")(({ theme }) => ({
  "& .footerNote": {
    color: "#424242",
    margin: "1rem 0 0",
    textAlign: "center",

    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: 600,
    },
  },
}));
