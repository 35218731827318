import { styled } from "@mui/material";

export const AuthContainerBox = styled("div")(({ theme }) => ({
  background: "#FAFAFA",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& .authContainer": {
    background: "#FFFFFF",
    borderRadius: "8px",
    padding: "3.5rem",
    width: "540px",
    height: "auto",
  },

  "& .logoContainer": {
    display: "flex",
    justifyContent: "center",
  },

  "& .headerSection": {
    margin: "3.5rem 0 2rem",
    textAlign: "center",

    "& .title": {
      color: "#001935",
      fontWeight: 600,
      fontSize: "1.5rem",
      marginBottom: "0.5rem",
    },

    "& .details": {
      color: "#424242",
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
}));
