import { AllEventBox } from "./AllEvents.styles";
import { PageTitle, StaticSideDialog } from "../../../components";
import * as React from "react";
import { Tab, Tabs } from "@mui/material";
import { EventCard, CreateEvent } from "../components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const AllEvents = () => {
  const [value, setValue] = React.useState<number>(0);
  const [openCreateEvent, setOpenCreateEvent] = React.useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCreateEvent = () => {
    setOpenCreateEvent(true);
  };

  return (
    <>
      <AllEventBox>
        <PageTitle
          title={"All Events"}
          btnText="New Event"
          click={handleCreateEvent}
        />

        <section className="mainSection">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Ongoing" {...a11yProps(0)} />
            <Tab label="Scheduled" {...a11yProps(1)} />
            <Tab label="Past" {...a11yProps(2)} />
            <Tab label="Draft" {...a11yProps(3)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            <div className="row">
              <div className="col-xxl-3 col-lg-4 col-md-6">
                <EventCard />
              </div>
              <div className="col-xxl-3 col-lg-4 col-md-6">
                <EventCard />
              </div>
              <div className="col-xxl-3 col-lg-4 col-md-6">
                <EventCard />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            Schedule Container
          </TabPanel>
          <TabPanel value={value} index={2}>
            Past Container
          </TabPanel>
          <TabPanel value={value} index={3}>
            Draft Container
          </TabPanel>
        </section>
      </AllEventBox>

      {/* Dialogs */}

      <StaticSideDialog
        title={"Create Event"}
        details={"Please enter the following details to create an event"}
        dialogProps={{
          open: openCreateEvent,
          onClose: () => {
            setOpenCreateEvent(false);
          },
        }}
      >
        <CreateEvent />
      </StaticSideDialog>
    </>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabBody"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
