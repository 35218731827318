import React from "react";
import { Base } from "../components";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  DashboardOverview,
  SignUp,
  SignIn,
  ForgetPassword,
  ResetPassword,
  VerifyAccount,
  AllEvents,
  Participants,
  Onboarding,
  Settings,
} from "../pages";

import {
  DASHBOARD_URL,
  EVENTS_URL,
  EVENTS_PARTICIPANTS_URL,
  SETTINGS_URL,
} from "../helpers/routes.url";

export const MainRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Redirect all unknown routes to dashboard */}
        <Route path={"*"} element={<Navigate to={DASHBOARD_URL} replace />} />

        <Route element={<Base />}>
          <Route path={DASHBOARD_URL} element={<DashboardOverview />} />
          <Route path={EVENTS_URL} element={<AllEvents />} />]
          <Route path={EVENTS_PARTICIPANTS_URL} element={<Participants />} />
          <Route path={SETTINGS_URL} element={<Settings />} />
        </Route>

        <Route path={"/sign-up"} element={<SignUp />} />
        <Route path={"/sign-in"} element={<SignIn />} />
        <Route path={"/forget-password"} element={<ForgetPassword />} />
        <Route path={"/reset-password"} element={<ResetPassword />} />
        <Route path={"/verify-account"} element={<VerifyAccount />} />
        <Route path={"/onboarding"} element={<Onboarding />} />
      </Routes>
    </Router>
  );
};
