import { styled } from "@mui/material";

export const OnboardingBox = styled("section")(({ theme }) => ({
  height: "100vh",
  width: "100%",

  "& .mainContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100% - 80px)",

    "& .title": {
      color: theme.palette.secondary.main,
      marginBottom: "1rem",
      fontSize: "1.25rem",
      fontWeight: 600,
    },

    "& .details": {
      color: "#424242",
      marginBottom: "2.5rem",
      fontSize: "1rem",
    },
  },

  "& .onboardCard": {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "8px",
    padding: "1.25rem",
    cursor: "pointer",
    transition: "0.3s all ease-in-out",

    "& .iconHolder": {
      width: "120px",
      height: "80px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#F5F5F5",
      borderRadius: "8px",
      border: "1px solid #E4E4E4",
      marginBottom: "1.25rem",
    },

    "& .name": {
      color: "#424242",
      fontSize: "1.05rem",
      fontWeight: 600,
    },

    "&:hover": {
      outline: `0.5px solid ${theme.palette.primary.main}`,
      transform: "scale(1.025)",
    },
  },
}));
