import { AuthContainer, PasswordInput } from "../components";
import { FDPButton } from "../../../components";
import { theme } from "../../../components";
import { Box } from "@mui/material";

export const ResetPassword = () => {
  return (
    <AuthContainer title="Reset password" details="Create a new password">
      <form>
        <Box
          sx={{
            mb: 2,
          }}
        >
          <PasswordInput handlePasswordChange={() => {}} />
        </Box>

        <Box
          sx={{
            mb: 4,
          }}
        >
          <PasswordInput handlePasswordChange={() => {}} />
        </Box>

        <FDPButton
          buttonProps={{
            fullWidth: true,
            sx: {
              background: theme.palette.primary.main,
              color: "#ffffff",

              "&:hover": {
                background: theme.palette.primary.light,
              },
            },
          }}
          text={"Reset Password"}
        />
      </form>
    </AuthContainer>
  );
};
