import { styled } from "@mui/material";

export const PageTitleBox = styled("section")(({ theme }) => ({
  display: "flex",
  //   alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "1.25rem",

  "& .title": {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },

  "& .dateContainer": {
    fontWeight: 600,
    textAlign: "end",

    "& .day": {
      fontSize: "1rem",
      color: "#787878",
    },

    "& .date": {
      fontSize: "1.25rem",
      color: theme.palette.primary.main,
    },
  },

  "& .primaryBtn": {
    background: theme.palette.primary.main,
    color: "#ffffff",
  },
}));
