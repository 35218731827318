import { styled } from "@mui/material";
import * as React from "react";

export const AdminViewCardBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "1rem",
  border: "1px solid #C9C9C9",
  borderRadius: "8px",
  cursor: "pointer",

  "& .name": {
    color: "#0C0C0C",
    marginBottom: "0.25rem",
    fontSize: "1rem",
    fontWeight: 400,
  },

  "& .email": {
    color: "#939393",
    fontSize: "0.75rem",
    fontWeight: 600,
  },

  "& .idBadge": {
    padding: "0.25rem 0.5rem",
    borderRadius: "100px",
    background: "#E7F3FE",
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontWeight: 600,
  },
}));

interface IProps {
  viewAdmin: () => void;
}

export const AdminViewCard: React.FC<IProps> = ({ viewAdmin }) => {
  return (
    <AdminViewCardBox onClick={viewAdmin}>
      <div>
        <h4 className="name">Olalekan Salami</h4>
        <small className="email">olalekansalami@gmail.com</small>
      </div>

      <div className="idBadge">ID12345678</div>
    </AdminViewCardBox>
  );
};
