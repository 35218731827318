import { styled } from "@mui/material";

export const PasswordBox = styled("div")(({ theme }) => ({
  "& .passwordCheck": {
    display: "flex",

    "& div": {
      width: "80%",
      marginTop: "0 !important",

      "& div": {
        height: "6px !important",

        "&:first-child": {
          borderRadius: "8px 0 0 8px",
        },
        "&:last-child": {
          borderRadius: "0 8px 8px 0",
        },
      },
    },

    "& p": {
      marginTop: "0 !important",
      width: "20%",
      color: "#424242",
      textTransform: "capitalize",
    },
  },

  "& .psText": {
    color: "#424242",
    fontSize: "0.75rem",
    fontWeight: 400,
  },
}));
