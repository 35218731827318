import { AccountBox } from "./Account.styles";
import { FDInput, FDPButton, StaticSideDialog } from "../../../../components";
import {
  AdminViewCard,
  AddAdmin,
  ManageAdmin,
  AddAdminFlowMode,
} from "./components";
import * as React from "react";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";

export const Account = () => {
  const [openAddAdmin, setOpenAddAdmin] = React.useState<boolean>(false);
  const [openManageAdmin, setOpenManageAdmin] = React.useState<boolean>(false);
  const [adminHeader, setAdminHeader] = React.useState<any>({
    title: "",
    details: "",
  });

  const handleAddAdmin = () => {
    setOpenAddAdmin(true);
  };

  const getAddAdminHeader = (headerText: any) => {
    setAdminHeader((prevState: any) => ({ ...headerText }));
  };

  const handleViewAdmin = () => {
    setOpenManageAdmin(true);
  };

  return (
    <AccountBox>
      <div className="row">
        {/* Business Section */}
        <div className="col-lg-6">
          <section className="sectionContainer">
            <div className="titleContainer">
              <h3 className="title">Business Profile</h3>
            </div>

            <form className="my-4">
              <TextField
                label="Business name"
                type="text"
                variant="standard"
                fullWidth
                className="mb-5"
              />
              <TextField
                label="Email address"
                type="email"
                variant="standard"
                fullWidth
                className="mb-5"
              />
              <TextField
                label="Address"
                type="text"
                variant="standard"
                fullWidth
                className="mb-5"
              />
              <div className="row mb-5">
                <div className="col-4">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>City</InputLabel>
                    <Select
                      // value={age}
                      // onChange={handleChange}
                      label="City"
                    >
                      <MenuItem value="">
                        <em>Select City</em>
                      </MenuItem>
                      <MenuItem value={"lagos"}>Victoria Island</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>State</InputLabel>
                    <Select
                      // value={age}
                      // onChange={handleChange}
                      label="State"
                    >
                      <MenuItem value="">
                        <em>Select State</em>
                      </MenuItem>
                      <MenuItem value={"lagos"}>Lagos</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-4">
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      // value={age}
                      // onChange={handleChange}
                      label="Country"
                    >
                      <MenuItem value="">
                        <em>Select Country</em>
                      </MenuItem>
                      <MenuItem value={"Nigeria"}>Nigeria</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <FDPButton text="Edit Profile" />
            </form>
          </section>
        </div>

        {/* Admin section */}
        <div className="col-lg-6">
          <section className="sectionContainer">
            <div className="titleContainer">
              <h3 className="title">Admins</h3>

              <div className="link" onClick={handleAddAdmin}>
                Add User
              </div>
            </div>

            <AdminViewCard viewAdmin={handleViewAdmin} />
          </section>
        </div>
      </div>

      {/* Dialogs */}

      <StaticSideDialog
        title={adminHeader.title}
        details={adminHeader.details}
        // backButtonClick={()=>handleGoBack(adminHeader)}
        dialogProps={{
          open: openAddAdmin,
          onClose: () => {
            setOpenAddAdmin(false);
          },
        }}
      >
        <AddAdmin handleHeaderText={getAddAdminHeader} />
      </StaticSideDialog>

      {/* Manage Admin */}
      <StaticSideDialog
        title={"Admin details"}
        dialogProps={{
          open: openManageAdmin,
          onClose: () => {
            setOpenManageAdmin(false);
          },
        }}
      >
        <ManageAdmin />
      </StaticSideDialog>
    </AccountBox>
  );
};
