import { styled } from "@mui/material";
import { fontWeight } from "@mui/system";

export const HeaderBox = styled("header")(({ theme }) => ({
  height: "75px",
  borderBottom: "1px solid #F2F2F2",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 1.5rem",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.drawerWidth,
  },

  [theme.breakpoints.down("xs")]: {
    padding: "2rem 1rem",
  },

  "& .searchContainer": {
    position: "relative",

    "& .searchIcon": {
      position: "absolute",
      left: "1rem",
      top: "0.9rem",
    },

    "& input": {
      background: "#FAFAFA",
      padding: "0.9rem 1rem 0.9rem 3rem",
      border: "1px solid #E4E4E4",
      borderRadius: "8px",
      width: "480px",

      "&:focus": {
        outline: "none",
        boxShadow: "none",
        border: "1px solid #0B61DD",
      },

      "&::placeholder": {
        fontSize: "0.875rem",
        fontWeight: 600,
        color: "#939393",
      },
    },
  },

  "& .notificationContainer": {
    position: "relative",

    "& .dot": {
      position: "absolute",
      right: 0,
      top: 0,
    },
  },

  "& .avatarImg": {
    width: "35px",
    height: "38px",
    borderRadius: "20px",
  },

  "& .greetings": {
    color: theme.palette.secondary.main,
  },

  "& .adminBadge": {
    color: theme.palette.secondary.main,
    padding: "0.5rem 1rem",
    borderRadius: "100px",
    background: "#9CEFEF",
  },

  "& .sidepan": {
    display: "flex",
    alignItems: "center",
    gap: "0 1.5rem",
    fontSize: "1rem",
    fontWeight: 600,
  },
}));
