import { styled } from "@mui/material";

export const TableBox = styled("div")(({ theme }) => ({
  // height: "77vh",
  width: "100%",

  "& .batchDeleeteContainer": {
    display: "flex",
    alignitems: "center",
    justifyContent: "space-between",
    background: "#E7F3FE",
    padding: "1rem",

    "& .deleteContainer": {
      color: "#F71C1C",
      display: "inline-flex",
      gap: "0.75rem",
      alignItems: "center",
      cursor: "pointer",

      "& .text": {
        fontWeight: 600,
        textDecoration: "underline",
      },
    },

    "& .selectAllText": {
      color: "#0B61DD",
      fontWeight: 600,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },

  "& .MuiDataGrid-root": {
    fontSize: "0.875rem",
    backgroundColor: " #FFFFFF",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#FAFAFA",

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 600,
      color: "#939393",
      textTransform: "uppercase",
      marginRight: ".5rem",
    },
  },

  "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },

  "& .status": {
    borderRadius: "4px",
    textAlign: "center",
    fontWeight: 600,
    padding: "0.25rem .5rem",
    width: "85px",
    fontSize: ".8rem",
  },

  "& .active-status": {
    color: "#17B557",
    backgroundColor: "#D2F9E2",
  },

  "& .failed-status": {
    color: "#D90808",
    backgroundColor: "#FDD2D2",
  },

  "& .expired-status": {
    color: "#787878",
    backgroundColor: "#E4E4E4",
  },

  "& .info-status": {
    color: "#001935",
    backgroundColor: "#FAE3A5",
  },

  "& .view-details": {
    color: "1rem",
    fontWeight: 600,
    textDecoration: "underline",
  },

  "& .MuiDataGrid-root .MuiDataGrid-columnHeader": {
    padding: "0 1rem",

    // "&:first-child": {
    //   padding: "0",
    // },
  },

  "& .MuiDataGrid-root .MuiDataGrid-cell": {
    textTransform: "capitalize",
    padding: "0 1rem",
  },
}));
