import * as React from "react";
import { HeaderBox } from "./header.style";
import NotificationIcon from "../../../assets/images/icon/Notification.svg";
import Avatar from "../../../assets/icon/Avatars.svg";
import ArrowDown from "../../../assets/images/icon/arrow-down.svg";
import { Menu, MenuItem } from "@mui/material";

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <HeaderBox>
      <div className="searchContainer">
        <svg
          className="searchIcon"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0ZM9 16C12.8675 16 16 12.8675 16 9C16 5.1325 12.8675 2 9 2C5.1325 2 2 5.1325 2 9C2 12.8675 5.1325 16 9 16ZM17.4853 16.0711L20.3137 18.8995L18.8995 20.3137L16.0711 17.4853L17.4853 16.0711Z"
            fill="#939393"
          />
        </svg>

        <input
          type="text"
          placeholder="Search by name, appointment or event"
          className="form-control"
        />
      </div>

      <div className="sidepan">
        <div className="notificationContainer">
          <svg
            className="dot"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill="#F71C1C" />
          </svg>

          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 8C15 4.68629 12.3137 2 9 2C5.68629 2 3 4.68629 3 8V16H15V8ZM17 16.6667L17.4 17.2C17.5657 17.4209 17.5209 17.7343 17.3 17.9C17.2135 17.9649 17.1082 18 17 18H1C0.72386 18 0.5 17.7761 0.5 17.5C0.5 17.3918 0.53509 17.2865 0.6 17.2L1 16.6667V8C1 3.58172 4.58172 0 9 0C13.4183 0 17 3.58172 17 8V16.6667ZM6.5 19H11.5C11.5 20.3807 10.3807 21.5 9 21.5C7.6193 21.5 6.5 20.3807 6.5 19Z"
              fill="#001935"
            />
          </svg>
        </div>

        <div className="greetings">
          Hello, <>ABC Company</>
        </div>

        <div className="adminBadge">Super Admin</div>

        <img
          src={Avatar}
          alt="avatar"
          className="avatarImg"
          onClick={handleClick}
        />
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </HeaderBox>
  );
};
