import { styled } from "@mui/material";

export const VisitorCardBox = styled("section")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "1rem",
  borderBottom: "1px solid #E4E4E4",

  "& .avatarContainer": {
    minWidth: "40px",
    minHeight: "40px",
    borderRadius: "8px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    fontWeight: 400,
    marginRight: "0.65rem",

    "&:nth-child(1)": {
      background: "#D2F9E2",
      color: "#18B356",
    },

    "&:nth-child(2)": {
      background: "#FDDFDF",
      color: "#B31414",
    },
  },

//   "& avatar1": {
//     background: "#D2F9E2",
//     color: "#18B356",
//   },

//   "& avatar2": {
//     background: "#FDDFDF",
//     color: "#B31414",
//   },

  "& .detailContainer": {
    "& .titleHolder": {
      display: "inline-flex",
      gap: "0 0.4rem",
      alignItems: "center",
      marginBottom: "1.5rem",

      "& .name": {
        fontSize: "1rem",
        fontWeight: 600,
        color: "#0C0C0C",
      },
    },

    "& .reason": {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#272727",
      marginBottom: "0.5rem",
    },

    "& .dateTime": {
      display: "inline-block",
      background: "#CEF7F7",
      borderRadius: "8px",
      padding: "0.25rem 0.65rem",
      color: "#0C0C0C",
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },

  "& .moreIcon": {
    cursor: "pointer",
  },

  "& .tag": {
    fontSize: "0.75rem",
    fontWeight: 600,
    padding: "0.25rem 0.5rem",
    border: "1px solid #272727",
    borderRadius: "100px",
    background: "#F5F5F5",
  },
}));
