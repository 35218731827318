import { EventCardBox } from "./EventCard.styles";

export const EventCard = () => {
  return (
    <EventCardBox>
      <div className="newTag">New</div>
      <div className="bannerHolder d-flex align-items-center justify-content-center">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33333 13.5008L6.66667 10.1675L15.8333 19.3342L21.6667 13.5008L26.6667 18.5008V3.33333H3.33333V13.5008ZM3.33333 18.2148V26.6667H8.50083L13.4763 21.6912L6.66667 14.8815L3.33333 18.2148ZM13.2148 26.6667H26.6667V23.2148L21.6667 18.2148L13.2148 26.6667ZM1.66667 0H28.3333C29.2538 0 30 0.7462 30 1.66667V28.3333C30 29.2538 29.2538 30 28.3333 30H1.66667C0.7462 30 0 29.2538 0 28.3333V1.66667C0 0.7462 0.7462 0 1.66667 0ZM20.8333 11.6667C19.4527 11.6667 18.3333 10.5474 18.3333 9.16667C18.3333 7.78595 19.4527 6.66667 20.8333 6.66667C22.214 6.66667 23.3333 7.78595 23.3333 9.16667C23.3333 10.5474 22.214 11.6667 20.8333 11.6667Z"
            fill="#787878"
          />
        </svg>
      </div>

      <h4 className="title">
        ABCD Conference: Making the world a better place for everyone
      </h4>
      <div className="dateTimeContainer">
        <div className="date">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.9987 0.166016V1.49935H8.9987V0.166016H10.332V1.49935H12.9987C13.3669 1.49935 13.6654 1.79783 13.6654 2.16602V12.8327C13.6654 13.2009 13.3669 13.4993 12.9987 13.4993H0.998698C0.630511 13.4993 0.332031 13.2009 0.332031 12.8327V2.16602C0.332031 1.79783 0.630511 1.49935 0.998698 1.49935H3.66536V0.166016H4.9987ZM12.332 6.83268H1.66536V12.166H12.332V6.83268ZM6.33203 8.16602V10.8327H2.9987V8.16602H6.33203ZM3.66536 2.83268H1.66536V5.49935H12.332V2.83268H10.332V4.16602H8.9987V2.83268H4.9987V4.16602H3.66536V2.83268Z"
              fill="#787878"
            />
          </svg>
          May 27, 2023
        </div>
        <div className="time">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.9987 14.1673C3.3168 14.1673 0.332031 11.1825 0.332031 7.50065C0.332031 3.81875 3.3168 0.833984 6.9987 0.833984C10.6806 0.833984 13.6654 3.81875 13.6654 7.50065C13.6654 11.1825 10.6806 14.1673 6.9987 14.1673ZM6.9987 12.834C9.94423 12.834 12.332 10.4462 12.332 7.50065C12.332 4.55513 9.94423 2.16732 6.9987 2.16732C4.05318 2.16732 1.66536 4.55513 1.66536 7.50065C1.66536 10.4462 4.05318 12.834 6.9987 12.834ZM7.66536 7.50065H10.332V8.83398H6.33203V4.16732H7.66536V7.50065Z"
              fill="#787878"
            />
          </svg>
          10:00AM (WAT)
        </div>
      </div>

      <div className="tagsContainer">
        <div className="meetingTag">Virtual</div>
        <div className="timeTag">2 hours</div>
        <div className="costTag">Free</div>
      </div>

      <div className="links">
        <div className="">View booking page</div>
        <div className="">
          {" "}
          <svg
            className="me-2"
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66653 3.00065V1.00065C2.66653 0.632464 2.96501 0.333984 3.3332 0.333984H11.3332C11.7014 0.333984 11.9999 0.632464 11.9999 1.00065V10.334C11.9999 10.7022 11.7014 11.0007 11.3332 11.0007H9.3332V13.0001C9.3332 13.3686 9.03327 13.6673 8.662 13.6673H0.671107C0.300393 13.6673 0 13.3709 0 13.0001L0.0017333 3.6679C0.00179997 3.29939 0.30176 3.00065 0.672947 3.00065H2.66653ZM1.33495 4.33398L1.33346 12.334H7.99987V4.33398H1.33495ZM3.99987 3.00065H9.3332V9.66732H10.6665V1.66732H3.99987V3.00065Z"
              fill="#0B61DD"
            />
          </svg>
          Copy link
        </div>
      </div>
    </EventCardBox>
  );
};
