import { AppointmentBox } from "./Appointment.styles";
import * as React from "react";

export const Appointment = () => {
  const [copyStatus, setCopyStatus] = React.useState("Copy");
  const codeAreaRef = React.useRef<any>(null);

  const copyCodeToClipboard = (e: any) => {
    let copiedText = codeAreaRef?.current?.innerText;
    // Copy the text inside the text field
    navigator.clipboard.writeText(copiedText);

    // Alert the copied text
    // alert("Copied the text: " + copiedText);

    setCopyStatus("Copied");
    setTimeout(() => {
      setCopyStatus("Copy");
    }, 3000);
  };

  return (
    <AppointmentBox className="col-lg-6">
      <h4 className="title">Generate Scheduling URL</h4>
      <p className="details">
        Copy the generated html link to embed in your website for faster
        appointment scheduling
      </p>

      <pre className="editor-interface">
        <div className="d-flex justify-content-end">
          <div
            className="pointer text-light mb-2"
            onClick={copyCodeToClipboard}
          >
            {copyStatus}
          </div>
        </div>

        <div ref={codeAreaRef}>
          <div className="html-tag">&lt;a</div>
          <div className="ps-3">
            <span className="html-attribute">class</span>
            <span className="html-attribute-value">="schedule_btn"</span>
          </div>
          <div className="ps-3">
            <span className="html-attribute">href</span>
            <span className="html-attribute-value">
              ="https://frontdesk.oneidtech.com/integrate/oneid global
              technologies ltd/LO-8E2D9E"
            </span>
          </div>
          <div className="ps-3">
            <span className="html-attribute">target</span>
            <span className="html-attribute-value">="_blank"</span>
          </div>
          <div className="html-tag">&gt;</div>
          <div className="html-text ps-3">Schedule Appointment</div>
          <span className="html-tag">&lt;/a&gt;</span>
        </div>
      </pre>
    </AppointmentBox>
  );
};
