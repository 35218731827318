import { FDInput } from "../../../../components";
import { PasswordBox } from "./PasswordInput.styles";
import PasswordStrengthBar from "react-password-strength-bar";
import * as React from "react";
import { Link } from "react-router-dom";

interface IProps {
  handlePasswordChange: () => void;
  value?: string;
}

export const PasswordInput: React.FC<IProps> = ({
  handlePasswordChange,
  value,
}) => {
  const [password, setPassword] = React.useState<string>("");

  return (
    <PasswordBox
      sx={{
        mb: 2,
      }}
    >
      <FDInput
        label={"Password"}
        placeholder={"Enter password"}
        type={"password"}
        fullWidth
        value={value}
        onChange={(e) => {
          handlePasswordChange();
          setPassword(e.target.value);
        }}
      />
      {password && (
        <>
          <PasswordStrengthBar className="passwordCheck" password={password} />
          <div className="psText">Password strength</div>
        </>
      )}
    </PasswordBox>
  );
};
