import { styled } from "@mui/material";

export const QuickLookCardBox = styled("section")(({ theme }) => ({
  background: "#ffffff",
  borderRadius: "8px",

  "& .titleContainer": {
    padding: "1rem",
    border: "1px solid #E4E4E4",

    "& .title": {
      fontWeight: 600,
      fontSize: "1.25rem",
    },
  },

  "& .body": {
    paddingBottom: "1.5rem",
  },
}));
