import { NavigatorBox } from "./navigator.style";
import { DrawerProps } from "@mui/material";
import { Drawer, List, ListItem } from "@mui/material";
import { navigatorData, INavigator, INavChildren } from "../../../helpers";
import { useLocation } from "react-router-dom";
import { LogoTextLightBg } from "../../lib/Logo";
import { useNavigate } from "react-router-dom";

interface Props {
  drawerProps?: DrawerProps;
}

export const Navigator: React.FC<Props> = ({ drawerProps }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const renderMenuwithIcon = (
    title: string,
    icon: any,
    link: string | undefined
  ) => {
    return (
      <div
        onClick={() => navigate(link as string)}
        className={`${location.pathname === link ? "activeLink" : ""}
        ${!link ? "itemTitleList" : ""} itemList `}
      >
        {icon && <div className="icon">{icon}</div>}
        <div>{title}</div>
      </div>
    );
  };

  return (
    <NavigatorBox variant="permanent" {...drawerProps}>
      <div className="logoSection">
        <LogoTextLightBg />
      </div>

      <List className="listContainer">
        {navigatorData.map((item: INavigator) => {
          return (
            <ListItem key={item.title} disablePadding>
              <div className="w-100">
                {renderMenuwithIcon(item.title, item.icon, item.link as string)}
                {item?.children?.length >= 1 && (
                  <>
                    <hr className="divider" />
                    {item.children.map(
                      ({ subTitle, icon, link }: INavChildren) => (
                        <div className="mb-2">
                          {renderMenuwithIcon(subTitle, icon, link)}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </ListItem>
          );
        })}
      </List>
    </NavigatorBox>
  );
};
