import { DataGrid, GridColDef, DataGridProps } from "@mui/x-data-grid";
import * as React from "react";
import { TableBox } from "./table.style";
import EmptyStateImg from "../../../assets/empty-state.svg";


interface Props extends DataGridProps {
  rows: any[];
  columns: GridColDef[] | any[] | any;
  height?: string | number | any;
  isCheckboxSelection?: boolean;
  sendSelectedRowToParent?: (arg: any[]) => void;
  batchDelete?: () => void;
  deleteType?: "API" | "Lookup";
}

export const DataTable: React.FC<Props> = ({
  rows,
  columns,
  height = "calc(100vh - 170px)",
  isCheckboxSelection = false,
  sendSelectedRowToParent,
  batchDelete,
  deleteType,
  ...props
}) => {
  const [selection, setSelection] = React.useState([]);
  // const [onDelete, setOnDelete] = React.useState(false);

  return (
    <TableBox style={{ height: height }}>
      {/* Only users that can view checkbox can create a selection */}
      {selection && selection.length >= 1 && isCheckboxSelection && (
        <>
          {deleteType === "API" && (
            <div className="batchDeleeteContainer">
              <div className="deleteContainer" onClick={batchDelete}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 4H22V6H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V6H2V4H7V2H17V4ZM9 9V17H11V9H9ZM13 9V17H15V9H13Z"
                    fill="#F71C1C"
                  />
                </svg>

                <span className="text">Delete</span>
              </div>
              <p className="mb-0 selectedText">
                All <strong>{selection.length}</strong> API Keys on this page
                are selected
              </p>
            </div>
          )}
        </>
      )}

      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={10}
        // rowsPerPageOptions={[5]}
        checkboxSelection={isCheckboxSelection}
        // onSelectionModelChange={(newSelectionModel: any) => {
        //   if (isCheckboxSelection) {
        //     setSelection(newSelectionModel);
        //     sendSelectedRowToParent!(newSelectionModel);
        //   }
        // }}
      />
    </TableBox>
  );
};
