import { styled } from "@mui/material";

export const AllEventBox = styled("section")(({ theme }) => ({
  "& .mainSection": {
    background: "#ffffff",
    borderRadius: "8px",
    minHeight: "80vh",

    "& .tabBody": {
      padding: "1.5rem 1rem",
    },
  },

  "& .MuiTabs-root": {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #E4E4E4",
    },

    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
      color: "#AEAEAE",
      fontSize: "1rem",
      fontWeight: 600,
    },

    "& .Mui-selected": {
      color: "#424242",
    },
  },
}));
