import { styled } from "@mui/material";

export const AccountBox = styled("section")(({ theme }) => ({
  "& .sectionContainer": {
    border: "1px solid #E4E4E4",
    borderRadius: "8px",
    background: "#FFFFFF",
    padding: "1rem",
    height: "100%",

    "& .titleContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "1.5rem",

      "& .title": {
        fontSize: "1.25rem",
        fontWeight: 600,
        color: theme.palette.secondary.main,
        marginBottom: "0",
      },

      "& .link": {
        fontSize: "1rem",
        fontWeight: 700,
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
  },
}));
