import {
  StandardTextFieldProps,
  TextField,
  FormHelperText,
} from "@mui/material"

import React from "react"
import { InputBox } from "./input.styles"
import { useField } from "formik"

export const FDInput: React.FC<StandardTextFieldProps> = ({
  fullWidth,
  variant = "outlined",
  ...props
}) => {
  // const [field, meta] = useField(props);

  return (
    <InputBox fullWidth={fullWidth}>
      <TextField className="input" {...props} />

      {/* {meta.touched && meta.error && (
        <FormHelperText className={"feedback"}>{meta.error}</FormHelperText>
      )} */}
    </InputBox>
  )
}
