import React from "react";
import { Navigator } from "../../components/layout/Navigator";
import { PageTitle } from "../../components";
import { DashboardBox } from "./Dashboard.styles";
import { FDPButton } from "../../components";
import { QuickLookCard, VisitorCard, EventCard } from "./components";

export const DashboardOverview = () => {
  return (
    <DashboardBox>
      <PageTitle title={"Dashboard"} datePosition={"right"} />

      <section className="metricCardContainer">
        <div className="metricCard">
          <svg
            className="icon"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.0007 0.666016V5.99935H35.0006V0.666016H40.334V5.99935H51.0006C52.4734 5.99935 53.6673 7.19327 53.6673 8.66602V51.3327C53.6673 52.8055 52.4734 53.9993 51.0006 53.9993H3.00065C1.5279 53.9993 0.333984 52.8055 0.333984 51.3327V8.66602C0.333984 7.19327 1.5279 5.99935 3.00065 5.99935H13.6673V0.666016H19.0007ZM48.334 27.3327H5.66732V48.666H48.334V27.3327ZM16.334 32.666V37.9993H11.0007V32.666H16.334ZM29.6673 32.666V37.9993H24.334V32.666H29.6673ZM43.0006 32.666V37.9993H37.6673V32.666H43.0006ZM13.6673 11.3327H5.66732V21.9993H48.334V11.3327H40.334V16.666H35.0006V11.3327H19.0007V16.666H13.6673V11.3327Z"
              fill="#E4E4E4"
            />
          </svg>

          <h5 className="title">Scheduled Appointments</h5>
          <div className="number">1,000</div>
          <div className="metricNote success">
            {" "}
            <svg
              className="me-2"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.6666 3.2183V11.3327H5.33327V3.2183L1.75726 6.79428L0.814453 5.85148L5.99993 0.666016L11.1853 5.85148L10.2425 6.79428L6.6666 3.2183Z"
                fill="#14994A"
              />
            </svg>{" "}
            20% Since yesterday
          </div>
        </div>

        <div className="metricCard">
          <svg
            className="icon"
            width="44"
            height="57"
            viewBox="0 0 44 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.666016 56.666C0.666016 44.8839 10.2173 35.3327 21.9993 35.3327C33.7815 35.3327 43.3327 44.8839 43.3327 56.666H37.9993C37.9993 47.8295 30.8359 40.666 21.9993 40.666C13.1628 40.666 5.99935 47.8295 5.99935 56.666H0.666016ZM21.9993 32.666C13.1593 32.666 5.99935 25.506 5.99935 16.666C5.99935 7.82602 13.1593 0.666016 21.9993 0.666016C30.8393 0.666016 37.9993 7.82602 37.9993 16.666C37.9993 25.506 30.8393 32.666 21.9993 32.666ZM21.9993 27.3327C27.8927 27.3327 32.666 22.5593 32.666 16.666C32.666 10.7727 27.8927 5.99935 21.9993 5.99935C16.106 5.99935 11.3327 10.7727 11.3327 16.666C11.3327 22.5593 16.106 27.3327 21.9993 27.3327Z"
              fill="#E4E4E4"
            />
          </svg>

          <h5 className="title">Incoming Visitors</h5>
          <div className="number">1,000</div>
          <div className="metricNote success">
            {" "}
            <svg
              className="me-2"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.6666 3.2183V11.3327H5.33327V3.2183L1.75726 6.79428L0.814453 5.85148L5.99993 0.666016L11.1853 5.85148L10.2425 6.79428L6.6666 3.2183Z"
                fill="#14994A"
              />
            </svg>{" "}
            20% Since yesterday
          </div>
        </div>

        <div className="metricCard">
          <svg
            className="icon"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.0007 0.666016V5.99935H35.0006V0.666016H40.334V5.99935H51.0006C52.4734 5.99935 53.6673 7.19327 53.6673 8.66602V51.3327C53.6673 52.8055 52.4734 53.9993 51.0006 53.9993H3.00065C1.5279 53.9993 0.333984 52.8055 0.333984 51.3327V8.66602C0.333984 7.19327 1.5279 5.99935 3.00065 5.99935H13.6673V0.666016H19.0007ZM48.334 27.3327H5.66732V48.666H48.334V27.3327ZM24.334 32.666V43.3327H11.0007V32.666H24.334ZM13.6673 11.3327H5.66732V21.9993H48.334V11.3327H40.334V16.666H35.0006V11.3327H19.0007V16.666H13.6673V11.3327Z"
              fill="#E4E4E4"
            />
          </svg>

          <h5 className="title">Upcoming Events</h5>
          <div className="number">1,000</div>
          <div className="metricNote failed">
            <svg
              className="me-2"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.6666 3.2183V11.3327H5.33327V3.2183L1.75726 6.79428L0.814453 5.85148L5.99993 0.666016L11.1853 5.85148L10.2425 6.79428L6.6666 3.2183Z"
                fill="#14994A"
              />
            </svg>
            20% Since yesterday
          </div>
        </div>
      </section>

      <section className="actionContainer">
        <p className="mb-0">What would you like to do today?</p>

        <div className="btnContainer">
          <FDPButton
            buttonProps={{
              className: "btnOutline",
            }}
            text="Check in a visitor"
          />
          <FDPButton
            buttonProps={{
              className: "btnOutline",
            }}
            text="Schedule an appointment"
          />
          <FDPButton
            buttonProps={{
              className: "btnOutline",
            }}
            text="Create an event"
          />
        </div>
      </section>

      <section className="row">
        <div className="col-md-6">
          <QuickLookCard title="Incoming Visitors">
            <>
              {["a", "b"].map((index) => (
                <VisitorCard key={index} />
              ))}
            </>
          </QuickLookCard>
        </div>

        <div className="col-md-6">
          <QuickLookCard title="Upcoming Events">
            <>
              <EventCard />
              <EventCard />
              <EventCard />
            </>
          </QuickLookCard>
        </div>
      </section>
    </DashboardBox>
  );
};
// https://twitter.com/Naija_PR/status/1656759382741250070?s=20
