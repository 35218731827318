import { SignUpBox } from "./SignUp.styles";
import { AuthContainer, PasswordInput } from "../components";
import { FDInput, FDSelect, FDPButton } from "../../../components";
import { theme } from "../../../components";
import { Link } from "react-router-dom";
import { Box, Checkbox } from "@mui/material";
import * as React from "react";

export const SignUp = () => {
  const [checked, setChecked] = React.useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <SignUpBox>
      <AuthContainer
        title="Hello!"
        details="Please provide the following details to create an account"
      >
        <form>
          <FDInput
            sx={{
              mb: 1,
            }}
            label={"Business name"}
            placeholder={"Enter business name"}
            type={"text"}
            fullWidth
          />

          <FDInput
            sx={{
              mb: 1,
            }}
            label={"Email address"}
            placeholder={"Enter business email address"}
            type={"email"}
            fullWidth
          />

          <PasswordInput handlePasswordChange={() => {}} />

          <Box
            sx={{
              mb: 4,
            }}
          >
            <FDSelect
              sx={{
                mb: 2,
              }}
              label={"Country"}
              placeholder={"Choose country"}
              type={"text"}
              fullWidth
              selectItems={["Nigeria", "Ghana", "Kenya"]}
            />

            <div className="d-flex gap-2">
              <Checkbox
                sx={{
                  padding: 0,
                }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />

              <div className="footerNote text-start">
                By signing up on this platform, you agree to OneID's{" "}
                <Link to="/sign-up">terms and conditions.</Link>
              </div>
            </div>
          </Box>

          <FDPButton
            buttonProps={{
              fullWidth: true,
              sx: {
                background: theme.palette.primary.main,
                color: "#ffffff",

                "&:hover": {
                  background: theme.palette.primary.light,
                },
              },
            }}
            text={"Create Account"}
          />

          <div className="footerNote">
            Already have an account? <Link to="/sign-in">Login</Link>
          </div>
        </form>
      </AuthContainer>
    </SignUpBox>
  );
};
