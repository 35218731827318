import { AuthContainer, PasswordInput } from "../components";
import { FDInput, FDSelect, FDPButton } from "../../../components";
import { theme } from "../../../components";


export const ForgetPassword = () => {
  return (
    <AuthContainer
      title="Forgot password?"
      details="Don't worry, It happens to the best of us."
    >
      <form>
        <FDInput
          sx={{
            mb: 4,
          }}
          label={"Email address"}
          placeholder={"Enter business email address"}
          type={"email"}
          fullWidth
        />

        <FDPButton
          buttonProps={{
            fullWidth: true,
            sx: {
              background: theme.palette.primary.main,
              color: "#ffffff",

              "&:hover": {
                background: theme.palette.primary.light,
              },
            },
          }}
          text={"Send Password Reset Link"}
        />
      </form>
    </AuthContainer>
  );
};
