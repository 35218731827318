import { styled } from "@mui/material";

export const VerifyAccountBox = styled("div")(({ theme }) => ({
  "& .authCodeContainer": {
    display: "flex",
    justifyContent: "center",
    gap: "0 1rem",
    margin: "2.5rem 0",

    "& input": {
      height: "64px",
      width: "64px",
      border: "1px solid #0B61DD",
      borderRadius: "12px",
      textAlign: "center",
    },
  },

  "& .footerNote": {
    color: "#424242",
    margin: "1rem 0 0",
    textAlign: "center",

    "& .resend": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: 600,
      cursor: "pointer"
    },
  },

  "& .timerContainer": {
    display: "block",
    color: "#424242",
    margin: "1.5rem 0 3rem",
    textAlign: "center",
    fontSize: "0.75rem",

    "& span":{
        color: "#F71C1C",
        fontWeight: 600
    }
  },
}));
