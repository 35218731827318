import { styled } from "@mui/material";

export const SettingsBox = styled("section")(({ theme }) => ({
  "& .MuiTabs-root": {
    "& .MuiTabs-scroller": {
      borderBottom: "1px solid #E4E4E4",
      marginBottom: "1.25rem !important",
    },

    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
      color: "#AEAEAE",
      fontSize: "1rem",
      fontWeight: 600,
    },

    "& .Mui-selected": {
      color: "#424242",
    },
  },
}));
