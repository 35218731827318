import { CreateEventBox } from "./CreateEvent.styles";
import { FDInput, FDPButton } from "../../../../components";
import { Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import { EvtSelect } from "..";
import { EventLocations, EventTypes } from "./MockData";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type EventItemName =
  | "location"
  | "eventType"
  | "eventDescription"
  | "eventBanner"
  | "eventLink";

interface IEventItem {
  name: EventItemName;
  state: boolean;
}

export const CreateEvent = () => {
  const [isMultipleSession, setIsMultipleSession] =
    React.useState<boolean>(false);
  const [isEventItemsState, setIsEventItemsState] = React.useState<
    IEventItem[]
  >([
    {
      name: "location",
      state: false,
    },
    {
      name: "eventType",
      state: false,
    },
    {
      name: "eventDescription",
      state: false,
    },
    {
      name: "eventBanner",
      state: false,
    },
    {
      name: "eventLink",
      state: false,
    },
  ]);
  const [locationState, setLocationState] = React.useState<boolean>(false);
  const [eventTypeState, setEventTypeState] = React.useState<boolean>(false);
  const [eventDescState, setEventDescState] = React.useState<boolean>(false);
  const [descValue, setDescValue] = React.useState<string>("");
  const [eventBannerState, setEventBannerState] =
    React.useState<boolean>(false);
  const [eventLinkState, setEventLinkState] = React.useState<boolean>(false);
  const hiddenFileInput = React.useRef<any>(null);

  const handleMultipleSession = () => {
    setIsMultipleSession(!isMultipleSession);
  };

  const handleClick = () => {
    hiddenFileInput?.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    console.log(fileUploaded, "file");
  };

  return (
    <CreateEventBox>
      <FDInput
        fullWidth
        type={"text"}
        label={"Event name"}
        placeholder={"Name of event"}
      />
      <div className="d-flex">
        <FormControlLabel
          control={
            <Checkbox
              checked={isMultipleSession}
              onChange={handleMultipleSession}
            />
          }
          label="Check this box if event has multiple sessions"
        />
      </div>

      {/* Location */}
      <div className="inputItemHolder mt-4">
        <svg
          className="icon"
          width="18"
          height="22"
          viewBox="0 0 18 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18.8995L13.9497 13.9497C16.6834 11.2161 16.6834 6.78392 13.9497 4.05025C11.2161 1.31658 6.78392 1.31658 4.05025 4.05025C1.31658 6.78392 1.31658 11.2161 4.05025 13.9497L9 18.8995ZM9 21.7279L2.63604 15.364C-0.87868 11.8492 -0.87868 6.15076 2.63604 2.63604C6.15076 -0.87868 11.8492 -0.87868 15.364 2.63604C18.8787 6.15076 18.8787 11.8492 15.364 15.364L9 21.7279ZM9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.8954 7 7 7.89543 7 9C7 10.1046 7.8954 11 9 11ZM9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9C13 11.2091 11.2091 13 9 13Z"
            fill="#424242"
          />
        </svg>
        {!locationState && (
          <div
            className="itemText"
            onClick={() => setLocationState(!locationState)}
          >
            Location
          </div>
        )}
        {locationState && (
          <div className="itemSelect">
            <EvtSelect
              label="Choose a location for this event"
              selectItems={EventLocations}
            />
          </div>
        )}
      </div>

      {/* Event Type */}
      <div className="inputItemHolder">
        <svg
          className="icon"
          width="24"
          height="19"
          viewBox="0 0 24 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.87564 0.00390625H19.1299C19.4498 0.00390625 19.7504 0.156986 19.9386 0.415736L23.7614 5.67213C23.9017 5.86501 23.8862 6.13011 23.7245 6.30536L12.3701 18.6059C12.1828 18.8088 11.8665 18.8215 11.6636 18.6342C11.6571 18.6281 7.86291 14.5185 0.281039 6.30536C0.119273 6.13011 0.103797 5.86501 0.244073 5.67213L4.06691 0.415736C4.25509 0.156986 4.55571 0.00390625 4.87564 0.00390625ZM5.38487 2.00391L2.58543 5.85314L12.0027 16.0553L21.4201 5.85314L18.6206 2.00391H5.38487Z"
            fill="#424242"
          />
        </svg>

        {!eventTypeState && (
          <div
            className="itemText"
            onClick={() => setEventTypeState(!eventTypeState)}
          >
            Event type
          </div>
        )}

        {eventTypeState && (
          <div className="itemSelect">
            <EvtSelect label="Choose an event type" selectItems={EventTypes} />
          </div>
        )}
      </div>

      {/* Add description */}
      <div className="inputItemHolder mt-4">
        <svg
          className="icon"
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H18V2H0V0ZM0 15H14V17H0V15ZM0 10H18V12H0V10ZM0 5H14V7H0V5Z"
            fill="#424242"
          />
        </svg>

        {!eventDescState && (
          <div
            className="itemText"
            onClick={() => setEventDescState(!eventDescState)}
          >
            Add description
          </div>
        )}

        {eventDescState && (
          <div className="itemSelect">
            <ReactQuill
              //   modules={["bold", "italic", "underline", "strike"]}
              theme="snow"
              value={descValue}
              onChange={setDescValue}
              placeholder="Type in a description of the event here..."
            />
          </div>
        )}
      </div>

      {/* Add event banner */}
      <div className="inputItemHolder mt-4">
        <svg
          className="icon"
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 12V15H22V17H19V20H17V17H14V15H17V12H19ZM19.0082 0C19.556 0 20 0.44495 20 0.9934V10H18V2H2V15.999L12 6L15 9V11.829L12 8.8284L4.827 16H12V18H0.9918C0.44405 18 0 17.5551 0 17.0066V0.9934C0 0.44476 0.45531 0 0.9918 0H19.0082ZM6 4C7.10457 4 8 4.89543 8 6C8 7.1046 7.10457 8 6 8C4.89543 8 4 7.1046 4 6C4 4.89543 4.89543 4 6 4Z"
            fill="#424242"
          />
        </svg>
        {!eventBannerState && (
          <div
            className="itemText"
            onClick={() => setEventBannerState(!eventBannerState)}
          >
            Add event banner
          </div>
        )}

        {eventBannerState && (
          <>
            <div className="itemSelect pointer px-3" onClick={handleClick}>
              Upload an image
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              className="d-none"
            />
          </>
        )}
      </div>

      {/* Generate event link */}
      <div className="inputItemHolder mt-4">
        <svg
          className="icon"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6572 11.8287L13.2429 10.4145L14.6572 9.00029C16.2193 7.43819 16.2193 4.90555 14.6572 3.34345C13.0951 1.78135 10.5624 1.78135 9.0003 3.34345L7.5861 4.75766L6.17188 3.34345L7.5861 1.92924C9.9292 -0.413913 13.7282 -0.413913 16.0714 1.92924C18.4145 4.27238 18.4145 8.07139 16.0714 10.4145L14.6572 11.8287ZM11.8287 14.6572L10.4145 16.0714C8.0714 18.4145 4.27238 18.4145 1.92923 16.0714C-0.41391 13.7282 -0.41391 9.92919 1.92923 7.58609L3.34345 6.17188L4.75766 7.58609L3.34345 9.00029C1.78135 10.5624 1.78135 13.0951 3.34345 14.6572C4.90555 16.2193 7.4382 16.2193 9.0003 14.6572L10.4145 13.243L11.8287 14.6572ZM11.8287 4.75766L13.2429 6.17188L6.17188 13.243L4.75766 11.8287L11.8287 4.75766Z"
            fill="#424242"
          />
        </svg>

        {!eventLinkState && (
          <div
            className="itemText"
            onClick={() => setEventLinkState(!eventLinkState)}
          >
            Generate event link
          </div>
        )}

        {eventLinkState && (
          <div className="itemSelect flex-column p-3 w-100">
            <div className="mb-3 me-auto"> Event Link</div>
            <div className="w-100">
              <FDInput
                fullWidth
                label="Event link"
                type={"text"}
                defaultValue={"frontdesk.com/abccompany/abcdconference"}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMultipleSession}
                    onChange={handleMultipleSession}
                  />
                }
                label="For keynote speakers/ special guests"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMultipleSession}
                    onChange={handleMultipleSession}
                  />
                }
                label="For all participants"
              />
              <br />
              <FDPButton text="Generate link" />
            </div>
          </div>
        )}
      </div>

      <FDPButton text="Create Event" />
    </CreateEventBox>
  );
};
