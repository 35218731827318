import { styled } from "@mui/material";

export const AddAdminBox = styled("div")(({ theme }) => ({
  "& .permissionCheckContainer": {
    padding: "0.5rem 1rem",
    display: "flex",
    alignItems: "center",
    border: "1px solid #E4E4E4",
    borderRadius: "8px",
    flexDirection: "column",
  },

  "& .switchForm": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginLeft: "0",
  },

  "& .form-info": {
    display: "flex",
    alignItems: "center",
    gap: "0 0.5rem",
    fontSize: "0.7rem",
    padding: "0.35rem 0.25rem",
    color: "#424242",
    background: "#F5F5F5",
    borderRadius: "8px",

    "& svg": {
      minWidth: "17px",
      minHeight: "17px",
    },
  },
}));
