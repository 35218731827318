export const DASHBOARD_URL = "/app";
export const APPOINTMENTS_URL = `${DASHBOARD_URL}/appointments`;
export const APPOINTMENTS_VISITOR_LOG_URL = `${APPOINTMENTS_URL}/visitor-log`;
export const APPOINTMENTS_ANALYTICS_URL = `${APPOINTMENTS_URL}/analytics`;
export const APPOINTMENTS_ARCHIVE_URL = `${APPOINTMENTS_URL}/archive`;

export const EVENTS_URL = `${DASHBOARD_URL}/events`;
export const EVENTS_PARTICIPANTS_URL = `${EVENTS_URL}/participants`;
export const EVENTS_ANALYTICS_URL = `${EVENTS_URL}/analytics`;
export const EVENTS_ARCHIVE_URL = `${EVENTS_URL}/archive`;

export const SETTINGS_URL = `${DASHBOARD_URL}/settings`;
