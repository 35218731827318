import * as React from "react";
import {
  FormControl,
  Select,
  SelectProps,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { EvtSelectBox } from "./EvtSelect.styles";

interface EvtSelectProps extends SelectProps {
  selectItems?: any[];
}

export const EvtSelect: React.FC<EvtSelectProps> = ({
  selectItems,
  ...props
}) => {
  const [openSelect, setOpenSelect] = React.useState<boolean>(true);

  return (
    <EvtSelectBox variant="standard" sx={{ minWidth: 250 }}>
      {/* <InputLabel id="demo-simple-select-standard-label">
        {props.label}
      </InputLabel> */}
      <Select
        // autoWidth={true}
        open={openSelect}
        onClick={() => setOpenSelect(!openSelect)}
        {...props}
      >
        {selectItems &&
          selectItems.map((item, id) => (
            <MenuItem
              value={item}
              key={id}
              onClick={() => setOpenSelect(!openSelect)}
            >
              <div className="d-flex align-items-center p-2">
                {item.icon && <div className="me-2">{item.icon}</div>}
                {item.name}
              </div>
            </MenuItem>
          ))}
      </Select>
    </EvtSelectBox>
  );
};
