import { SignInBox } from "./SignIn.styles";
import { AuthContainer, PasswordInput } from "../components";
import { FDInput, FDSelect, FDPButton } from "../../../components";
import { theme } from "../../../components";
import { Link } from "react-router-dom";
import { Box, Checkbox } from "@mui/material";
import * as React from "react";

export const SignIn = () => {
  const [checked, setChecked] = React.useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <SignInBox>
      <AuthContainer
        title="Welcome back!"
        details="Please log in to your account"
      >
        <form>
          <FDInput
            sx={{
              mb: 1,
            }}
            label={"Email address"}
            placeholder={"Enter business email address"}
            type={"email"}
            fullWidth
          />

          <Box
            sx={{
              mb: 4,
            }}
          >
            <PasswordInput handlePasswordChange={() => {}} />

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <Checkbox
                  sx={{
                    padding: 0,
                  }}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />

                <div className="footerNote text-start mt-0">Remember me</div>
              </div>

              <Link to="/forget-password" className="forgetPasswordText">
                Forgot password?
              </Link>
            </div>
          </Box>

          <FDPButton
            buttonProps={{
              fullWidth: true,
              sx: {
                background: theme.palette.primary.main,
                color: "#ffffff",

                "&:hover": {
                  background: theme.palette.primary.light,
                },
              },
            }}
            text={"Log in"}
          />

          <div className="footerNote">
            Don’t have an account? <Link to="/sign-up">Sign Up</Link>
          </div>
        </form>
      </AuthContainer>
    </SignInBox>
  );
};
