import { AuthContainer, PasswordInput } from "../components";
import { FDInput, FDSelect, FDPButton } from "../../../components";
import { theme } from "../../../components";
import AuthCode from "react-auth-code-input";
import * as React from "react";
import { VerifyAccountBox } from "./VerifyAccount.styles";

export const VerifyAccount = () => {
  const [result, setResult] = React.useState<string>();

  const handleOnChange = (res: string) => {
    setResult(res);
  };

  return (
    <AuthContainer
      title="Verify your email"
      details="We sent a one time verification code to hello@abccompany.com"
    >
      <VerifyAccountBox>
        <form>
          <AuthCode
            allowedCharacters="numeric"
            containerClassName="authCodeContainer"
            length={4}
            onChange={handleOnChange}
          />

          <div className="footerNote">
            Haven’t received the code?{" "}
            <span className="resend">Resend code</span>
          </div>

          <small className="timerContainer">
            Request a new code in <span className="time">00:25</span>
          </small>

          <FDPButton
            buttonProps={{
              fullWidth: true,
              sx: {
                background: theme.palette.primary.main,
                color: "#ffffff",

                "&:hover": {
                  background: theme.palette.primary.light,
                },
              },
            }}
            text={"Continue"}
          />
        </form>
      </VerifyAccountBox>
    </AuthContainer>
  );
};
