import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { theme } from "./themes";
// import { DateTimeProvider } from "components/lib"

interface Props {
  children: React.ReactNode;
}
export const ThemeProvider: React.FC<Props> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    {children}
    {/* <DateTimeProvider>{children}</DateTimeProvider> */}
  </MuiThemeProvider>
);
