import { styled } from "@mui/material";

export const CreateEventBox = styled("form")(({ theme }) => ({
  padding: "1rem 0",

  "& .inputItemHolder": {
    display: "flex",
    alignItems: "center",
    gap: "0 1.15rem",
    marginBottom: "2.5rem",

    "& .itemText": {
      cursor: "pointer",
      color: "#424242",
      fontWeight: 400,
      fontSize: "1rem",
      padding: "0.5rem",
    },

    "& .icon": {
      width: "24px",
    },

    "& .itemSelect": {
      background: "#F5F5F5",
      display: "flex",
      alignItems: "center",
      minHeight: "55px",
      borderRadius: "8px",

      "& .ql-toolbar, .ql-container": {
        border: "none",
      },
    },

    "&:hover": {
      "& .icon": {
        "& path": {
          fill: theme.palette.primary.main,
        },
      },

      "& .itemText": {
        background: "#F5F5F5",
      },
    },
  },
}));
