import React, { useState } from "react";
import { BaseBox } from "./base.style";
import { Navigator } from "../Navigator";
import { Header } from "../Header";
import { Outlet, useNavigate } from "react-router-dom";
import { Hidden } from "@mui/material";

interface Props {
  children?: React.ReactNode;
}

export const Base: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <BaseBox>
      <aside className="drawer">
        <Hidden smUp implementation="js">
          <Navigator
            drawerProps={{
              style: { width: "270px" },
              variant: "temporary",
              open: mobileOpen,
            }}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator
            drawerProps={{
              open: !mobileOpen,
              style: { width: "270px", zIndex: 1000 },
            }}
          />
        </Hidden>
      </aside>

      <div className="appContent">
        <Header />

        <main className="mainContent">
          {children}
          <Outlet />
        </main>
      </div>
    </BaseBox>
  );
};
