import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import React from "react";
import { SelectInputBox } from "./select.styles";

interface SelectItemsProps {
  name: string;
}

interface FDSelectProps extends SelectProps {
  hasObjects?: boolean;
  selectItems?: string[];
  helperText?: string | false;
  selectInputProps?: SelectInputProps;
  selectableObjects?: SelectItemsProps[];
}

export const FDSelect: React.FC<FDSelectProps> = ({
  hasObjects,
  helperText,
  selectItems,
  selectableObjects,
  ...props
}) => {

  return (
    <SelectInputBox>
      <InputLabel>{props.label}</InputLabel>
      <Select className="select__input" {...props}>
        {hasObjects
          ? selectableObjects?.map((item, idx) => (
              <MenuItem value={item.name} key={idx}>
                {item.name}
              </MenuItem>
            ))
          : selectItems?.map((item, idx) => (
              <MenuItem value={item} key={idx}>
                {item}
              </MenuItem>
            ))}
      </Select>
      {props.error && helperText && (
        <FormHelperText color="danger">{helperText}</FormHelperText>
      )}
    </SelectInputBox>
  );
};
