import { EventCardBox } from "./EventCard.styles";

export const EventCard = () => {
  return (
    <EventCardBox>
      <div className="dateContainer">
        <div>
          <div className="day">22</div>
          <div className="month">Jun</div>
        </div>
      </div>

      <div className="detailContainer">
        <div className="titleHolder">
          <h4 className="name mb-0">Meeting with Leks</h4>
          <div className="time">1 Hour</div>
        </div>

        <div className="booking">View booking page</div>
        <div className="link">
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66653 3.00065V1.00065C2.66653 0.632464 2.96501 0.333984 3.3332 0.333984H11.3332C11.7014 0.333984 11.9999 0.632464 11.9999 1.00065V10.334C11.9999 10.7022 11.7014 11.0007 11.3332 11.0007H9.3332V13.0001C9.3332 13.3686 9.03327 13.6673 8.662 13.6673H0.671107C0.300393 13.6673 0 13.3709 0 13.0001L0.0017333 3.6679C0.00179997 3.29939 0.30176 3.00065 0.672947 3.00065H2.66653ZM1.33495 4.33398L1.33346 12.334H7.99987V4.33398H1.33495ZM3.99987 3.00065H9.3332V9.66732H10.6665V1.66732H3.99987V3.00065Z"
              fill="#0B61DD"
            />
          </svg>
          Copy link
        </div>
      </div>

      <div className="actionContainer ms-auto">
        <svg
          className="moreIcon"
          width="18"
          height="4"
          viewBox="0 0 18 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z"
            fill="black"
          />
        </svg>
      </div>
    </EventCardBox>
  );
};
