import { styled } from "@mui/material";

export const SignInBox = styled("div")(({ theme }) => ({
  "& .footerNote": {
    color: "#424242",
    margin: "1rem 0 0",
    textAlign: "center",

    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: 600,
    },
  },

  "& .forgetPasswordText": {
    color: "#F71C1C",
    fontWeight: 700,
    fontSize: "0.875rem",
    textDecoration: "none",
  },
}));
