import { VisitorCardBox } from "./VisitorCard.styles";

export const VisitorCard = () => {
  return (
    <VisitorCardBox>
      <div className="avatarContainer">HL</div>

      <div className="detailContainer">
        <div className="titleHolder">
          <h4 className="name mb-0">Hanty Lara</h4>
          <div className="tag">Yet to arrive</div>
        </div>

        <div className="reason">Appointment with the HR Manager</div>
        <div className="dateTime">22 Jun, 2023; 04:00 PM</div>
      </div>

      <div className="actionContainer ms-auto">
        <svg
        className="moreIcon"
          width="18"
          height="4"
          viewBox="0 0 18 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z"
            fill="black"
          />
        </svg>
      </div>
    </VisitorCardBox>
  );
};
