import { QuickLookCardBox } from "./QuickLookCard.styles";
import * as React from "react";

interface IProps {
  title: string;
  children: JSX.Element;
}

export const QuickLookCard: React.FC<IProps> = ({ title, children }) => {
  return (
    <QuickLookCardBox>
      <div className="titleContainer">
        <h4 className="mb-0 title">{title} </h4>
      </div>

      <div className="body">
          {children}
      </div>
    </QuickLookCardBox>
  );
};
