import { PageTitleBox } from "./PageTitle.styles";
import { FDPButton } from "..";
import * as React from "react";

interface IProps {
  title: string;
  btnText?: string;
  datePosition?: "left" | "right";
  click?: () => void;
}

export const PageTitle: React.FC<IProps> = ({
  title,
  btnText,
  datePosition,
  click,
}) => {
  const renderDateTitle = () => {
    return (
      <div
        className={`${
          datePosition === "left" ? "text-start" : ""
        }  dateContainer`}
      >
        <div className="day">Wednesday,</div>
        <div className="date">April 19, 2023</div>
      </div>
    );
  };

  return (
    <PageTitleBox>
      <div className="">
        <h3 className="title">{title}</h3>
        {datePosition === "left" && <>{renderDateTitle()}</>}
      </div>

      <div className="">
        {datePosition === "right" && <>{renderDateTitle()}</>}
        {btnText && (
          <FDPButton
            click={click}
            buttonProps={{
              className: "primaryBtn",
            }}
            text={btnText}
          />
        )}
      </div>
    </PageTitleBox>
  );
};
