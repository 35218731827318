import { styled, FormControl } from "@mui/material";

export const EvtSelectBox = styled(FormControl)(({ theme }) => ({
  "& label+.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
    marginTop: "0.5rem !important",
  },

  "& .MuiInputBase-root-MuiInput-root-MuiSelect-root::before": {
    borderBottom: "none !important",
  },

  "& .MuiButtonBase-root-MuiMenuItem-root": {
    padding: "1.2rem 2rem !important",
  },

  "& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
    backgroundColor: "#E2EDFE !important",
  },
}));
