import { SettingsBox } from "./Settings.styles";
import { PageTitle } from "../../components";
import { Tab, Tabs } from "@mui/material";
import * as React from "react";
import { Account, Appointment } from "./components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const Settings = () => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <SettingsBox>
      <PageTitle title="Settings" />

      <section className="mainSection">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Account" {...a11yProps(0)} />
          <Tab label="Appointments" {...a11yProps(1)} />
          <Tab label="Events" {...a11yProps(2)} />
          <Tab label="General" {...a11yProps(3)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Account />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Appointment />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Past Container
        </TabPanel>
        <TabPanel value={value} index={3}>
          Draft Container
        </TabPanel>
      </section>
    </SettingsBox>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabBody"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
