import { AddAdminBox } from "./AddAdmin.styles";
import { FDInput, FDPButton } from "../../../../../../components";
import {
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import * as React from "react";

export type AddAdminFlowMode = "Profile" | "AddPermission";

interface IProps {
  handleHeaderText: (arg: any) => void;
  handleGoBack?: (arg: AddAdminFlowMode) => void;
}

export const AddAdmin: React.FC<IProps> = ({
  handleHeaderText,
  handleGoBack,
}) => {
  const [adminMode, setAdminMode] = React.useState<AddAdminFlowMode>("Profile");
  const [permissionLevel, setPermissionLevel] = React.useState<any>(null);

  React.useEffect(() => {
    handleHeaderText({
      title: "Add an Admin User",
      details:
        "Please enter the following information to create an admin account",
    });
  }, []);

  const handleAddAdminProfile = () => {
    setAdminMode("AddPermission");
    // Set Modal header
    handleHeaderText({
      title: "Set Permissions",
      details:
        "Please check the level of permissions for the admin user you are about to add to your FrontDesk account",
    });
  };

  const handleSubmt = () => {};

  const handleBack = (selectedMode: AddAdminFlowMode) => {
    // handleGoBack(selectedMode);
    // setAdminMode(selectedMode);
  };

  const handleChangePermissionLevel = (e: any) => {
    setPermissionLevel(e.target.value);
  };

  return (
    <AddAdminBox>
      <form className="my-4">
        {adminMode === "Profile" && (
          <>
            <FDInput
              label="First name"
              placeholder="Enter the first name of the admin"
              type={"text"}
              fullWidth
              className="mb-3"
            />
            <FDInput
              label="Last name"
              placeholder="Enter the last name of the admin"
              type={"text"}
              fullWidth
              className="mb-3"
            />
            <FDInput
              label="Email address"
              placeholder="Enter email address"
              type={"email"}
              fullWidth
              className="mb-1"
            />

            <Alert severity="warning" icon={false} className="mb-5">
              An email invite with the Admin ID and password will be sent to the
              user to log in as an admin on your FrontDesk account
            </Alert>

            <FDPButton text="Set Permisson" click={handleAddAdminProfile} />
          </>
        )}

        {adminMode === "AddPermission" && (
          <>
            <RadioGroup
              name="peermisionLevel"
              className="d-flex gap-3 mb-5"
              onChange={handleChangePermissionLevel}
            >
              <div className="permissionCheckContainer">
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Level 1 Admin Permissions"
                  className="w-100"
                />

                {permissionLevel === "1" && (
                  <div className="w-100 mt-3">
                    <div className="mb-4">
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch defaultChecked />}
                        label="Check-in"
                        className="switchForm"
                      />
                      <div className="form-info">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99935 17.3334C4.39697 17.3334 0.666016 13.6024 0.666016 9.00008C0.666016 4.39771 4.39697 0.666748 8.99935 0.666748C13.6017 0.666748 17.3327 4.39771 17.3327 9.00008C17.3327 13.6024 13.6017 17.3334 8.99935 17.3334ZM8.99935 15.6667C12.6813 15.6667 15.666 12.682 15.666 9.00008C15.666 5.31818 12.6813 2.33341 8.99935 2.33341C5.31745 2.33341 2.33268 5.31818 2.33268 9.00008C2.33268 12.682 5.31745 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                            fill="#424242"
                          />
                        </svg>
                        Admin will be able to check-in visitors/ participants on
                        arrival
                      </div>
                    </div>

                    <div className="mb-4">
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch defaultChecked />}
                        label="Can view visitors/ participants details"
                        className="switchForm"
                      />
                      <div className="form-info">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99935 17.3334C4.39697 17.3334 0.666016 13.6024 0.666016 9.00008C0.666016 4.39771 4.39697 0.666748 8.99935 0.666748C13.6017 0.666748 17.3327 4.39771 17.3327 9.00008C17.3327 13.6024 13.6017 17.3334 8.99935 17.3334ZM8.99935 15.6667C12.6813 15.6667 15.666 12.682 15.666 9.00008C15.666 5.31818 12.6813 2.33341 8.99935 2.33341C5.31745 2.33341 2.33268 5.31818 2.33268 9.00008C2.33268 12.682 5.31745 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                            fill="#424242"
                          />
                        </svg>
                        Admin will be able to view all related details of a
                        visitor/ participant{" "}
                      </div>
                    </div>

                    <div className="mb-4">
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch defaultChecked />}
                        label="Can export visitors/ participants list"
                        className="switchForm"
                      />
                      <div className="form-info">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99935 17.3334C4.39697 17.3334 0.666016 13.6024 0.666016 9.00008C0.666016 4.39771 4.39697 0.666748 8.99935 0.666748C13.6017 0.666748 17.3327 4.39771 17.3327 9.00008C17.3327 13.6024 13.6017 17.3334 8.99935 17.3334ZM8.99935 15.6667C12.6813 15.6667 15.666 12.682 15.666 9.00008C15.666 5.31818 12.6813 2.33341 8.99935 2.33341C5.31745 2.33341 2.33268 5.31818 2.33268 9.00008C2.33268 12.682 5.31745 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                            fill="#424242"
                          />
                        </svg>
                        Admin will be able to download visitor/ participants
                        list in any selected format
                      </div>
                    </div>

                    <div className="mb-4">
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch defaultChecked />}
                        label="Can update password"
                        className="switchForm"
                      />
                      <div className="form-info">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99935 17.3334C4.39697 17.3334 0.666016 13.6024 0.666016 9.00008C0.666016 4.39771 4.39697 0.666748 8.99935 0.666748C13.6017 0.666748 17.3327 4.39771 17.3327 9.00008C17.3327 13.6024 13.6017 17.3334 8.99935 17.3334ZM8.99935 15.6667C12.6813 15.6667 15.666 12.682 15.666 9.00008C15.666 5.31818 12.6813 2.33341 8.99935 2.33341C5.31745 2.33341 2.33268 5.31818 2.33268 9.00008C2.33268 12.682 5.31745 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                            fill="#424242"
                          />
                        </svg>
                        Admin will be able to update their password
                      </div>
                    </div>

                    <div className="mb-4">
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch defaultChecked />}
                        label="Can add other admin"
                        className="switchForm"
                      />
                      <div className="form-info">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99935 17.3334C4.39697 17.3334 0.666016 13.6024 0.666016 9.00008C0.666016 4.39771 4.39697 0.666748 8.99935 0.666748C13.6017 0.666748 17.3327 4.39771 17.3327 9.00008C17.3327 13.6024 13.6017 17.3334 8.99935 17.3334ZM8.99935 15.6667C12.6813 15.6667 15.666 12.682 15.666 9.00008C15.666 5.31818 12.6813 2.33341 8.99935 2.33341C5.31745 2.33341 2.33268 5.31818 2.33268 9.00008C2.33268 12.682 5.31745 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                            fill="#424242"
                          />
                        </svg>
                        Admin will be able to add another admin user{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="permissionCheckContainer">
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Level 2 Admin Permissions"
                  className="w-100"
                />

                {permissionLevel === "2" && (
                  <div className="w-100 mt-3">
                    <div className="mb-4">
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Switch defaultChecked />}
                        label="Check-in only"
                        className="switchForm"
                      />
                      <div className="form-info">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.99935 17.3334C4.39697 17.3334 0.666016 13.6024 0.666016 9.00008C0.666016 4.39771 4.39697 0.666748 8.99935 0.666748C13.6017 0.666748 17.3327 4.39771 17.3327 9.00008C17.3327 13.6024 13.6017 17.3334 8.99935 17.3334ZM8.99935 15.6667C12.6813 15.6667 15.666 12.682 15.666 9.00008C15.666 5.31818 12.6813 2.33341 8.99935 2.33341C5.31745 2.33341 2.33268 5.31818 2.33268 9.00008C2.33268 12.682 5.31745 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                            fill="#424242"
                          />
                        </svg>
                        Admin will only be able to check-in guests on arrival,
                        will not be able to perform other functions of a Level 1
                        Admin
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </RadioGroup>

            <FDPButton text="Add Admin" click={handleSubmt} />
          </>
        )}
      </form>
    </AddAdminBox>
  );
};
