import {
  ButtonProps,
  CircularProgress,
  Fab,
  FabProps,
  Tooltip,
} from "@mui/material";
import React from "react";
//   import { RiCustomerService2Line as CustomerServiceIcon } from "react-icons/ri"
//   import { Ripple } from "../../styledComponents/animatedStyles"
import { ButtonBox } from "./Buttons.styles";

interface FDPButtonProps {
  text: string;
  loading?: boolean;
  buttonProps?: ButtonProps;
  click?: () => void;
}

export const FDPButton: React.FC<FDPButtonProps> = ({
  text,
  loading,
  buttonProps,
  click,
}) => {
  return (
    <ButtonBox onClick={click} {...buttonProps}>
      {loading ? <CircularProgress /> : text}
    </ButtonBox>
  );
};
