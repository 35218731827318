import React from "react";
import { MainRoutes } from "./routes/main-routes";
import { ThemeProvider } from "./components";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <ThemeProvider>
      <MainRoutes />
    </ThemeProvider>
  );
}

export default App;
