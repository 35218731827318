import { styled } from "@mui/material";

export const EventCardBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "1rem",
  borderBottom: "1px solid #E4E4E4",

  "& .dateContainer": {
    minHeight: "48px",
    minWidth: "40px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "0.65rem",
    background: "#D2F9E2",
    color: "#18B356",

    "& .day": {
      fontSize: "1rem",
      fontWeight: 600,
    },

    "& .month": {
      fontSize: "0.6rem",
      fontWeight: 400,
      textTransform: "uppercase",
    },
  },

  "& .detailContainer": {
    "& .titleHolder": {
      display: "inline-flex",
      gap: "0 0.4rem",
      alignItems: "center",
      marginBottom: "0.7rem",

      "& .name": {
        fontSize: "1rem",
        fontWeight: 400,
        color: "#0C0C0C",
      },

      "& .time": {
        display: "inline-block",
        background: "#CEF7F7",
        borderRadius: "8px",
        padding: "0.25rem 0.65rem",
        color: "#0C0C0C",
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },

    "& .booking": {
      fontSize: "0.875rem",
      fontWeight: 600,
      color: theme.palette.primary.main,
      marginBottom: "0.25rem",
    },

    "& .link": {
      display: "inline-flex",
      alignItems: "center",
      gap: "0 0.35rem",
      fontWeight: 600,
      fontSize: "0.75rem",
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },

  "& .moreIcon": {
    cursor: "pointer",
  },
}));
