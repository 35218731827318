import { Drawer, styled } from "@mui/material";

export const NavigatorBox = styled(Drawer)(({ theme }) => ({
  background: "#FFFFFF",
  height: "100%",
  display: "block",

  "& .MuiDrawer-paper": {
    zIndex: 1000,
    width: theme.drawerWidth,
  },

  "& .MuiList-root": {
    overflowY: "auto",
  },

  "& .logoSection": {
    margin: "1.5rem 0 2rem 1.75rem",
  },

  "& .MuiListItem-root": {
    margin: "0 0 1.5rem 0",
    padding: "0 1.25rem 0 1.25rem",
  },

  "& .divider": {
    margin: "0 0 0.5rem",
    background: "#E4E4E4",
  },

  "& .itemList": {
    padding: "1rem",
    color: "#787878",
    fontSize: "0.95rem",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "all .3s ease",

    "& .icon": {
      marginRight: "1rem",

      "& path": {
        transition: "all .3s ease",
        fill: "#787878",
      },
    },

    "&:hover": {
      color: "#0B61DD",
      background: "#F4F9FF",

      "& .icon": {
        "& path": {
          fill: " #0B61DD",
        },
      },
    },
  },

  "& .itemTitleList": {
    cursor: "auto",

    "&:hover": {
      color: "#787878",
      background: "unset",

      "& .icon": {
        "& path": {
          fill: "unset",
        },
      },
    },
  },

  "& .activeLink": {
    background: "#F4F9FF",
    borderRadius: "8px",
    color: "#0B61DD",
    fontWeight: 600,

    "& .icon": {
      "& path": {
        fill: " #0B61DD",
      },
    },
  },
}));
